export const BG_COVER = `https://ik.imagekit.io/zaanfa2/atas-opik/cover.jpg?updatedAt=1699969333066`;
export const BG_COUNTING = `https://ik.imagekit.io/zaanfa2/atas-opik/counting.jpg?updatedAt=1699969332853`;
export const BG_FOOTER = `https://ik.imagekit.io/zaanfa2/atas-opik/footer.jpg?updatedAt=1699969333011`;
export const BG_LOVESTORY = `https://ik.imagekit.io/zaanfa2/atas-opik/lovestory-2.jpg?updatedAt=1699969336776`;
export const BG_FILTER = `https://ik.imagekit.io/zaanfa2/atas-opik/filter.jpg?updatedAt=1700519258992`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/zaanfa2/atas-opik/dekstop.jpg?updatedAt=1699969332961`;

// images welcome section
export const IMG_LAMPION = `https://user-images.githubusercontent.com/10141928/150158769-8c451a18-4b83-46fe-97d9-3fc8f87f7e06.png`;
export const IMG_WELCOME_1 = `https://ik.imagekit.io/zaanfa2/atas-opik/w1.jpg?updatedAt=1699969337266`;
export const IMG_WELCOME_2 = `https://ik.imagekit.io/zaanfa2/atas-opik/w2.jpg?updatedAt=1699969336519`;
export const IMG_WELCOME_3 = `https://ik.imagekit.io/zaanfa2/atas-opik/w3.jpg?updatedAt=1699969337613`;
export const IMG_WELCOME_4 = `https://ik.imagekit.io/zaanfa2/atas-opik/w4.jpg?updatedAt=1699969332621`;
export const IMG_WELCOME_5 = `https://ik.imagekit.io/zaanfa2/atas-opik/w5.jpg?updatedAt=1699969332948`;

// couple image
export const IMG_MAN = `https://ik.imagekit.io/zaanfa2/atas-opik/man.jpg?updatedAt=1699969336721`;
export const IMG_GIRL = `https://ik.imagekit.io/zaanfa2/atas-opik/girl.jpg?updatedAt=1699969336285`;
export const IMG_PHOTO_COVER = `https://user-images.githubusercontent.com/10141928/150639548-4e4b612b-35c4-4fda-88d7-fa3cdd97295d.png`;
export const IMG_LOGO_WEDDING = `https://user-images.githubusercontent.com/10141928/150645258-2dddbcd4-a473-4d90-b2f5-825d0fe1737e.png`;
export const IMG_LOVE_STORY = `https://ik.imagekit.io/zaanfa2/atas-opik/lovestory.jpg?updatedAt=1699969336559`;

// video assets
export const VIDEO_COVER = `https://user-images.githubusercontent.com/10141928/150642624-d39b2ace-41e7-4464-a1ed-1cadc519503a.mp4`;

// image photo gallery cover
export const IMG_PHOTO_1 = `https://ik.imagekit.io/zaanfa2/atas-opik/g1.jpg?updatedAt=1699969333021`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/zaanfa2/atas-opik/g2.jpg?updatedAt=1699969332750`;
export const IMG_PHOTO_3 = `https://ik.imagekit.io/zaanfa2/atas-opik/g3.jpg?updatedAt=1699969333071`;
export const COVER_GALLERY = `https://ik.imagekit.io/zaanfa2/atas-opik/gallery.jpg?updatedAt=1699969332831`;

// image icons
export const IMG_ICON_HAND = `https://user-images.githubusercontent.com/10141928/150661877-d975b35b-3e55-4333-b216-2f9df6ca2584.png`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};
export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;